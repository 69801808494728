import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Link from '../../../../../../../../components/Link'
import NavLink from '../../../../../../../../components/NavLink'

const LinkNav = styled(Link)`
  color: ${(props) => props.theme.color.textPrimary};
  margin: 0 0.7em;
  white-space: nowrap;
  cursor: pointer;
`
const Wrapper = styled.div`
  position: relative;
  &:first-child ${LinkNav} {
    margin-left: 0;
  }
  &:last-child ${LinkNav} {
    margin-right: 0;
  }
`
const MiniArrow = styled.i`
  display: inline-block;
  border-color: ${(props) => props.theme.color.textPrimary} transparent transparent;
  border-width: 4px;
  border-style: solid;
  vertical-align: middle;
  margin-left: 0.5em;
  transform-origin: 50% 25%;
  transition: transform 0.2s ease-in-out;
  ${(props) =>
    props.open &&
    css`
      transform: rotate(180deg);
    `};
`
const NavSecondLevel = styled(({ open, ...props }) => <div {...props} />)`
  position: absolute;
  left: -0.3em;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.color.backgroundContent};
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: ${(props) => props.theme.sizes.gapColHalf};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-top: ${(props) => props.theme.tablet.sizes.gapColHalf};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-top: ${(props) => props.theme.mobile.sizes.gapColHalf};
  }
  padding: 1em 0;
  ${(props) =>
    !props.open &&
    css`
      display: none;
    `};
`

class ItemNavPrimary extends Component {
  render() {
    const { name, url, type, items, openNavPoint, ...props } = this.props
    delete props.selectNavPoint
    delete props.position
    return (
      <Wrapper {...props}>
        <LinkNav to={url} onClick={!url ? this.clickHandler : null}>
          {name}
          {type !== 'single' && <MiniArrow open={openNavPoint} />}
        </LinkNav>
        {items && items.length && (
          <NavSecondLevel open={openNavPoint}>
            {items.map(({ name: name2, url: url2 }, index2) => (
              <NavLink key={name2 + url2 + index2} to={url2}>
                {name2}
              </NavLink>
            ))}
          </NavSecondLevel>
        )}
      </Wrapper>
    )
  }
  clickHandler = () => {
    let position = this.props.type
    if (position === 'single') {
      return
    }
    if (position === 'multiple') {
      position = this.props.position
    }
    this.props.selectNavPoint(position)
  }
}

ItemNavPrimary.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string,
  type: PropTypes.oneOf(['single', 'districts', 'multiple', 'more']).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ),
  selectNavPoint: PropTypes.func,
  position: PropTypes.number.isRequired,
  openNavPoint: PropTypes.bool,
}

ItemNavPrimary.defaultProps = {}

export default ItemNavPrimary

export { LinkNav as LinkNav0ItemNavPrimary }
