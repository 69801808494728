import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Tooltip from '@mui/material/Tooltip'
import Icon from '../../../../components/Icon/index'

const Wrapper = styled(Tooltip)`
  cursor: help;
  .tooltip-bottom {
    margin-top: 0;
  }
`

const InnerWrapper = styled.span``

const ErrorIcon = styled(Icon)`
  color: ${(props) => props.theme.color.stol};
  font-size: ${(props) => props.theme.fontSize.title3};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.title3};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.title3};
  }
  padding-right: 0.5em;
`

const IconError = ({ errorMessage = '&nbsp;', ...props }) => (
  <Wrapper title={errorMessage} placement="top-end" classes={{ tooltip: 'tooltiop-bottom' }} {...props}>
    <InnerWrapper>
      <ErrorIcon icon="error" />
    </InnerWrapper>
  </Wrapper>
)

IconError.propTypes = {
  errorMessage: PropTypes.string,
}

export default IconError
