import React from 'react'

function IELogo(props) {
  return (
    <svg viewBox="0 0 260 260" {...props}>
      <filter id="c">
        <feGaussianBlur stdDeviation="4" />
      </filter>
      <g transform="translate(-6.58 -.95)">
        <radialGradient
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(.6237 .7816 -.7859 .6271 144.971 -69.01)"
          fy="113.59"
          fx="138.954"
          r="104.426"
          cy="115.761"
          cx="143.516"
          id="a"
        >
          <stop offset="0" stop-color="#89eafe" />
          <stop offset=".262" stop-color="#86e8fd" />
          <stop offset=".436" stop-color="#7ddff9" />
          <stop offset=".583" stop-color="#6ed2f2" />
          <stop offset=".716" stop-color="#59bee9" />
          <stop offset=".84" stop-color="#3ea5dd" />
          <stop offset=".954" stop-color="#1c87cf" />
          <stop offset="1" stop-color="#0d79c8" />
        </radialGradient>
        <path
          d="M100.5 117.8c2.6-23.3 21-42 43.9-42 25.7 0 43.4 18 45 42zm84.2 49.2c-6.5 13.4-20 22.1-40.4 22.6-28.3.7-43.2-23.4-43.9-44.1h145.8c.5-4.2.8-8.4.8-12.8 0-56.4-45.7-102.1-102.1-102.1-56.4 0-102.1 45.7-102.1 102.1 0 56.4 45.7 102.1 102.1 102.1 44.4 0 82.1-28.3 96.2-67.8z"
          fill="url(#a)"
          stroke="#2064a9"
          stroke-miterlimit="10"
        />
      </g>
      <g transform="translate(-6.58 -.95)">
        <linearGradient y2="79.694" x2="234.565" y1="64.28" x1="237.882" gradientUnits="userSpaceOnUse" id="b">
          <stop offset="0" stop-color="#ffb700" />
          <stop offset=".86" stop-color="#ffb700" stop-opacity="0" />
        </linearGradient>
        <path
          d="M235.5 63.7c-.8 3.1-1.6 5.8-2.1 8.1-.4 1.8-.6 7.1-.6 7.1s3.8-2.4 4.7-3.6c.8-1.1 1.9-5.8 2.9-10.5z"
          fill="url(#b)"
          fill-opacity=".95"
        />
        <path
          d="M240.6 65c.7-3.1 1.3-6.3 1.6-8.5 1.3-8.1 4-51.3-53.7-39-77.4 16.4-120.2 77.1-127 85.5-12.9 16-51.3 77.8-41.2 115.8 5.9 22.2 29.5 22.1 46.7 18.6l-1.2-6.1c-29.2 4.7-33.6-17.5-29.6-34.8 4.9-21.6 20.8-57.1 72.2-107.1 45.6-44.4 94-69.2 119.2-56.9 13.4 6.5 10.8 20.6 7.9 31.4z"
          fill="#ffb700"
          fill-opacity=".95"
        />
        <g filter="url(#c)">
          <path
            d="M188.5 17.5C111.1 33.9 68.3 94.6 61.5 103c-8.1 10-26.1 38-36 66.8-.6 2.3-1.3 4.6-2.1 6.6C55.2 94.9 134.2 37.2 191.6 16.9c-1 .2-2.1.4-3.1.6z"
            fill="#ffffa6"
          />
        </g>
        <linearGradient y2="226.499" x2="87.933" y1="235.127" x1="66.431" gradientUnits="userSpaceOnUse" id="d">
          <stop offset="0" stop-color="#ffb700" />
          <stop offset="1" stop-color="#ffb700" stop-opacity="0" />
        </linearGradient>
        <path
          d="M67 237.3c9.4-2 17.1-5 18.8-5.9 1.6-.9 7.1-3.9 12.1-6.9l-4.4-2.5c-2.4.9-5.2 2-8.3 3.3-7.3 3-13.9 4.9-19.4 5.7z"
          fill="url(#d)"
          fill-opacity=".95"
        />
      </g>
    </svg>
  )
}

export default IELogo
