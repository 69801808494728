import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ItemNavPrimary from './components/ItemNavPrimary'

const Wrapper = styled.nav`
  display: flex;
`
const DisplayNavPrimary = ({ navigationStructure, selectNavPoint, openNavPoint, ...props }) => (
  <Wrapper {...props}>
    {navigationStructure.map(({ name, url, type, districts, submenu, items }, index) => (
      <ItemNavPrimary
        key={name + url + index}
        name={name}
        url={url}
        type={type}
        submenu={submenu && submenu}
        districts={districts && districts}
        items={items}
        selectNavPoint={selectNavPoint}
        position={index}
        openNavPoint={openNavPoint === type ? true : openNavPoint === index}
      />
    ))}
  </Wrapper>
)

DisplayNavPrimary.propTypes = {
  navigationStructure: PropTypes.arrayOf(PropTypes.shape(ItemNavPrimary.propTypes)),
  position: PropTypes.number,
  selectNavPoint: ItemNavPrimary.propTypes.selectNavPoint,
  openNavPoint: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

DisplayNavPrimary.defaultProps = {
  navigationStructure: [],
}

export default DisplayNavPrimary
export { Wrapper as Wrapper0DisplayNavPrimary }
