import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import Link from '../../../../../../../../components/Link'
import NavLink from '../../../../../../../../components/NavLink'
import translate from '../../../../../../../../modules/translate'

const Wrapper = styled.div`
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  @media (min-width: 700px) {
    display: none;
  }
  @media (max-width: 700px) {
    display: block;
  }
`

const MiniArrow = styled.i`
  display: inline-block;
  border-color: ${(props) => props.theme.color.textPrimary} transparent transparent;
  border-width: 4px;
  margin-top: 4px;
  border-style: solid;
  vertical-align: middle;
  transform-origin: 50% 25%;
  transition: transform 0.2s ease-in-out;
  position: absolute;
  right: 5px;
  ${(props) =>
    props.open &&
    css`
      transform: rotate(180deg);
    `};
`

const NavigationLink = styled(({ type, ...props }) => <Link {...props} />)`
  margin: 0.2em 0;
  padding: 0;
  white-space: normal;
  left: 5px;
  ${(props) =>
    props.type === 'big' &&
    css`
      color: ${(props) => props.theme.color.stol};
      font-weight: ${(props) => props.theme.fontWeight.bold};
      font-size: ${(props) => props.theme.fontSize.title1};
      @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
        font-size: ${(props) => props.theme.tablet.fontSize.title1};
      }
      @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
        font-size: ${(props) => props.theme.mobile.fontSize.title1};
      }
      &:hover {
        color: ${(props) => props.theme.color.stol};
      }
    `};
  &:before {
    left: -1em;
  }
  ${(props) =>
    props.single === 'true' &&
    css`
      width: 100%;
    `}
`

const TitleWrapper = styled.div`
  break-inside: avoid-column;
  width: 100%;
  height: auto;
  margin: 0.2em 0;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const ContentWrapper = styled.div`
  width: 100%;
  height: 0;
  transition: height 0.2s ease-in-out;
  visibility: hidden;

  flex-direction: column;
  ${(props) =>
    props.open &&
    css`
      display: flex;
      visibility: visible;
      height: auto;
    `};
`

const StyledNavLink = styled(NavLink)`
  padding: 0.7em 0;
  margin-top: 0;
  margin-bottom: 0;
  font-size: ${(props) => props.theme.fontSize.title2};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.title2};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.title2};
  }
  &:before {
    left: -1em;
  }
`

class Collapsible extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
    }
    this.toggleOpen = this.toggleOpen.bind(this)
  }
  toggleOpen() {
    if (!this.state.open) {
      this.setState({
        open: true,
      })
    } else {
      this.setState({
        open: false,
      })
    }
  }
  render() {
    const { currentColumn, closeDropdownCallback, ...props } = this.props
    return (
      <Wrapper onClick={this.toggleOpen} {...props}>
        <TitleWrapper>
          <NavigationLink
            to={currentColumn[0].url}
            onClick={currentColumn[0].url && closeDropdownCallback}
            single={(currentColumn.length <= 1).toString()}
            type="big"
          >
            {currentColumn[0].name}
          </NavigationLink>
          {currentColumn.length > 1 && <MiniArrow open={this.state.open} />}
        </TitleWrapper>
        <ContentWrapper open={this.state.open}>
          {currentColumn.length > 1 && currentColumn[0].url && (
            <StyledNavLink to={currentColumn[0].url} onClick={currentColumn[0].url && closeDropdownCallback}>
              {translate('category_all')}
            </StyledNavLink>
          )}
          {currentColumn.slice(1).map(({ name, type, url }) => {
            return (
              <StyledNavLink key={url + name} to={url} onClick={url && closeDropdownCallback}>
                {type !== 'big' && name}
              </StyledNavLink>
            )
          })}
        </ContentWrapper>
      </Wrapper>
    )
  }
}

export default Collapsible
