import React from 'react'

const SPlusNeg = (props) => (
  <svg version="1.1" id="PlusIconNeg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.457 13.642" {...props}>
    <defs>
      <style>{'.splus_neg_svg{fill:#7e7e7e;}'}</style>
    </defs>
    <path
      className="splus_neg_svg"
      d="M131.657,232.092a.854.854,0,0,1,.637.2c.182.168.252.536.252,1.157h2.447c.134-1.93-1.41-2.751-3.1-2.751-1.61,0-3.524.588-3.524,2.518,0,2.852,4.4,2.433,4.4,4.329a.693.693,0,0,1,0,.106.872.872,0,0,1-.921.816c-.506,0-.775-.134-.909-.419a2.626,2.626,0,0,1-.117-1.074l-2.447-.052c-.135,2.5,1.309,3.054,3.49,3.054,2.065,0,3.339-1.157,3.339-2.718,0-2.869-4.4-2.8-4.4-4.212C130.8,232.441,131.1,232.092,131.657,232.092Z"
      transform="translate(-126.608 -227.552)"
    />
    <path
      className="splus_neg_svg"
      d="M15.457,0H2.184A2.185,2.185,0,0,0,0,2.184v9.272a2.184,2.184,0,0,0,2.179,2.186H13.273a2.185,2.185,0,0,0,2.184-2.184V9.639h0V0ZM10.364,2.323h1.807V.516h.961V2.323h1.81v.961h-1.81v1.81h-.961V3.284H10.364Zm4.752,9.135A1.844,1.844,0,0,1,13.273,13.3H2.181a1.843,1.843,0,0,1-1.84-1.845V2.184A1.843,1.843,0,0,1,2.184.341H6.339l8.778,8.95v2.167Z"
    />
  </svg>
)

export default SPlusNeg
