import React, { memo } from 'react'
import styled from 'styled-components'
import AdUnit from '../AdUnit'

const WrapperLink = styled.a`
  display: none;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
`

const Wallpaper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: no-repeat fixed center;
  z-index: -1;
`
const StyledAdUnit = styled(AdUnit)`
  display: none;
`

const AdWallpaper = ({ ...props }) => (
  <WrapperLink className="wallpaper-link" {...props}>
    <Wallpaper className="wallpaper" />
    <StyledAdUnit adId="WALLPAPER" />
  </WrapperLink>
)

export default memo(AdWallpaper)
