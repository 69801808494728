import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import AdUnit from '../../../../components/AdUnit'
import options from '../../../../config/options'

const SuperBannerContainer = styled.div`
  height: 150px;
  max-width: 100%;
  @media print {
    display: none;
    min-height: unset;
  }
  @media (max-width: 1080px) {
    height: 0px;
  }
`
const SuperBannerAdUnit = styled(AdUnit)`
  position: relative;
  text-align: center;
  width: 1078px;
  background-color: ${(props) => props.superBannerBackground};
  @media (max-width: 1680px) {
    margin-left: 160px;
    margin-right: 300px;
  }
  @media (max-width: 1540px) {
    margin-left: 0;
  }
  @media (max-width: 1380px) {
    margin-right: 0;
  }
  @media (max-width: 1080px) {
    width: 100%;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media (max-width: 778px) {
    height: 100px;
    padding-top: ${(props) => props.theme.mobile.sizes.gapVerticalHalf};
    padding-bottom: ${(props) => props.adId !== 'H1' && props.theme.mobile.sizes.gapVerticalHalf};
  }
`

const AdSuperBanner = ({ mobile, active, adId, superBannerBackground, location, ...props }) => {
  active = true
  if (mobile && !active) {
    return false
  }
  if (mobile && active) {
    return (
      <SuperBannerAdUnit superBannerBackground={superBannerBackground} adId={adId} mode={'mobile_tablet'} {...props} />
    )
  }
  return (
    <SuperBannerContainer {...props}>
      {active && <SuperBannerAdUnit adId={adId} mode={'desktop'} />}
    </SuperBannerContainer>
  )
}

AdSuperBanner.propTypes = {
  /**
   * Show mobile version
   */
  mobile: PropTypes.bool,
  active: PropTypes.bool,
  adId: PropTypes.string,
}

AdSuperBanner.defaultProps = {
  mobile: false,
  active: true,
  adId: 'S1',
}

export default AdSuperBanner
