import React from 'react'
import styled from 'styled-components'
import Link from '../../components/Link'
import useScript from 'react-script-hook'
import errorLog from '../../modules/error-log'

const StyledLink = styled(Link)`
  color: ${(props) => props.theme.color.textSecondary};

  &:hover {
    color: ${(props) => props.theme.color.stol};
  }
`

const Iubenda = () => {
  const [, error] = useScript({
    src: '//cdn.iubenda.com/iubenda.js',
  })

  if (error) {
    errorLog({
      info: 'iubenda script',
      error: error,
    })
  }

  return (
    <React.Fragment>
      <StyledLink
        to="https://www.iubenda.com/privacy-policy/88316793"
        className="iubenda-nostyle no-brand iubenda-embed"
        title="Privacy Policy "
      >
        Privacy Policy
      </StyledLink>
      &nbsp;|&nbsp;
      <StyledLink
        to="https://www.iubenda.com/privacy-policy/88316793/cookie-policy"
        title="Cookie Policy "
        className="iubenda-nostyle no-brand iubenda-embed"
      >
        Cookie Policy
      </StyledLink>
    </React.Fragment>
  )
}

export default Iubenda
