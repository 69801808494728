import React, { Component } from 'react'
import styled from 'styled-components'
import translate from '../../../../../../modules/translate'
import Input, { Input0Input } from '../../../../../../components/Input'
import Icon from '../../../../../../components/Icon'
import { withRouter } from 'react-router-dom'

const Wrapper = styled.div``
const StyledInput = styled(Input)`
  ${Input0Input} {
    padding: 0.2em 0;
  }
`
const StyledIcon = styled(Icon)`
  font-size: 1.5em;
  margin-right: 0.5em;
  color: ${(props) => props.theme.color.textSecondary};
  margin-bottom: 0.05em;
  cursor: pointer;
`

class InputSearchHeader extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searchTerm: '',
    }
    this.handleSearchTermChange = this.handleSearchTermChange.bind(this)
  }
  handleSearchTermChange(event) {
    this.setState({
      searchTerm: event.target.value,
    })
  }
  render() {
    const { props } = this.props
    return (
      <Wrapper {...props}>
        <StyledInput
          prepend={
            <StyledIcon
              onClick={() => {
                window.location.href = '/suche/' + this.state.searchTerm
              }}
              icon="search"
            />
          }
          value={this.state.searchTerm}
          onChange={this.handleSearchTermChange}
          type="search"
          placeholder={translate('search_placeholder')}
          onKeyPress={(e) => {
            if (e.key === 'Enter' && window) {
              window.location.href = '/suche/' + this.state.searchTerm
            }
          }}
        />
      </Wrapper>
    )
  }
}

export default withRouter(InputSearchHeader)
export { Wrapper as Wrapper0InputSearchHeader }
