import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Icon from '../../../../components/Icon'

const Wrapper = styled.div`
  z-index: 1300;
  position: fixed;
  left: 0;
  bottom: 0;
  @media (max-width: 600px) {
    left: unset;
    right: 0;
  }
`
const WrapperConfig = styled.div``
const ShowButton = styled.div`
  cursor: pointer;
`
const CloseButton = styled.div`
  cursor: pointer;
  padding: 10px 10px 0;
`
const Link = styled.a`
  padding: 10px 10px 0;
  display: block;
`

const StyledIcon = styled(Icon)`
  height: 20px;
`

const StagingConfigTemplate = ({ ...props }) => {
  const [stateShowTemplate, setStateShowTemplate] = useState({
    show: false,
  })

  const handleOptions = () => {
    handleShow()
    window.openHiddenOptions()
  }

  function handleShow() {
    if (stateShowTemplate.show) {
      setStateShowTemplate({ show: false })
    } else {
      setStateShowTemplate({ show: true })
    }
  }
  const version = require('../../../../../package.json').version
  return (
    <Wrapper {...props}>
      {stateShowTemplate.show && (
        <WrapperConfig>
          <Link href="#" onClick={handleOptions}>
            Options
          </Link>
          <Link href={'/abmelden'}>Abmelden</Link>
          <CloseButton onClick={handleShow}>Schließen</CloseButton>
          <hr />v {version}
        </WrapperConfig>
      )}
      {!stateShowTemplate.show && (
        <ShowButton onClick={handleShow}>
          <StyledIcon icon={'config'}></StyledIcon>
        </ShowButton>
      )}
    </Wrapper>
  )
}
StagingConfigTemplate.propTypes = {}
StagingConfigTemplate.defaultProps = {}
export default StagingConfigTemplate
