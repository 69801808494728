import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Icon from '../../../../../../components/Icon'
import styled, { css } from 'styled-components'
import Link from '../../../../../../components/Link'
import { setStoredItem, getStoredItem } from '../../../../../../modules/stored-item'
import { useQuery } from '@tanstack/react-query'
import hasItems from '../../../../../../modules/has-items'
import routes from '../../../../../../topics/routes'

const StyledIcon = styled(Icon)`
  ${(props) =>
    props.active
      ? css`
          color: ${(props) => props.theme.color.stol};
        `
      : css`
          color: ${(props) => props.theme.color.textSecondary};
        `}
`

const StyledLink = styled(Link)`
  color: ${(props) => props.theme.color.textPrimary};
`

const IconNewsFeed = ({ ...props }) => {
  const [updateIcon, setUpdateIcon] = useState(false)

  const { isFetching, data } = useQuery(routes.getQueryKey('newsticker'))
  const newstickerArticles = data && data.data && data.data.articles
  const lastArticle = hasItems(newstickerArticles) && newstickerArticles[0]

  if (isFetching && !updateIcon) {
    setUpdateIcon(true)
  }
  if (lastArticle && !updateIcon) {
    setStoredItem('lastNewsFeedArticleId', lastArticle.id, true)
  }
  const clientLastNewsFeedArticleId =
    !isNaN(parseFloat(getStoredItem('lastNewsFeedArticleId', true))) &&
    parseFloat(getStoredItem('lastNewsFeedArticleId', true)) !== undefined
      ? parseFloat(getStoredItem('lastNewsFeedArticleId', true))
      : 0
  const showRed =
    updateIcon && lastArticle && clientLastNewsFeedArticleId && clientLastNewsFeedArticleId !== lastArticle.id
  return (
    <StyledLink to={routes.generatePathByRouteName('newsticker')} {...props}>
      <StyledIcon icon="clock" active={showRed} />
    </StyledLink>
  )
}

IconNewsFeed.propTypes = {
  as: PropTypes.func,
}

export default IconNewsFeed
