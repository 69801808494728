import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Link from '../../../../components/Link'
import isServerSide from '../../../../modules/is-server-side'

const StyledLink = styled(Link)`
  color: ${(props) => props.theme.color.textSecondary};
  padding-right: 5px;
  padding-top: 5px;
  &:hover {
    color: ${(props) => props.theme.color.stol};
  }
`
const Wrapper = styled.div``
const PrivacyManager = ({ ...props }) => {
  function openPm() {
    if (!isServerSide()) {

      window._sp_.loadPrivacyManagerModal(174573)
    }
  }

  return <StyledLink onClick={() => openPm()}>Privacy Einstellungen</StyledLink>
}
PrivacyManager.propTypes = {}
PrivacyManager.defaultProps = {}
export default PrivacyManager
