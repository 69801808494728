import React from 'react'

const SafariSvg = (props) => {
  return (
    <svg width="50px" height="50px" viewBox="0 0 5120 5120" xmlns="http://www.w3.org/2000/svg">
      <linearGradient id="a" x2="0" y2="100%">
        <stop offset="0" stop-color="#19d7ff" />

        <stop offset="1" stop-color="#1e64f0" />
      </linearGradient>

      <g fill="#ffffff">
        <rect width="5130" height="5120" rx="1050" />

        <circle cx="2560" cy="2560" r="2240" fill="url(#a)" />

        <path fill="red" d="M4090 1020 2370 2370l4e2 4e2z" />

        <path d="M1020 4090l1350-1720 4e2 4e2z" />
      </g>

      <path
        stroke="#ffffff"
        stroke-width="30"
        d="M2560 540v330m0 3370v330m350-4e3-57 325m-586 3318-57 327M3250 662l-113 310M1984 4138l-113 310m339-3878 57 325m586 3318 57 327M1870 662l113 310m1152 3166 113 310M1552 810l166 286m1685 2918 165 286M1265 1010l212 253m2166 2582 212 253M1015 1258l253 212m2582 2168 253 212M813 1548l286 165m2920 1685 286 165M665 1866l310 113m3166 1150 310 113M574 2202l326 58m3320 588 325 57M545 2555h330m3370 0h330M575 2905l325-57m3320-586 325-57M668 3245l310-113m3165-1152 310-113M815 3563l286-165m2920-1685 286-165M1016 3850l253-212m2580-2166 253-212M1262 41e2l212-253m2166-2582 212-253M1552 43e2l166-286m1685-2918 165-286M2384 548l16 180m320 3656 16 180M2038 610l47 174m950 3544 47 174M1708 730l76 163m1550 3326 77 163M1404 904l103 148m2106 3006 103 148M1135 1130l127 127m2596 2596 127 127M910 14e2l148 103m3006 2107 146 1e2M734 1703l163 76m3326 1550 163 77M614 2033l174 47m3544 950 174 47M553 2380l180 16m3656 320 180 16m-4014 0 180-16m3656-320 180-16M614 3077l174-47m3544-950 174-47M734 3407l163-76m3326-1550 163-76M910 3710l148-103m3006-2107 146-1e2M1404 4206l103-148m2105-3006 104-148M1708 4380l77-163M3335 890l77-163M2038 45e2l47-174m950-3544 47-174m-698 3952 16-180m320-3656 16-180"
      />
    </svg>
  )
}

export default SafariSvg
