import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Icon from '../../../../../../../../components/Icon'
import Link from '../../../../../../../../components/Link'

const Wrapper = styled(Link)`
  color: ${(props) => props.theme.color.textPrimary};
  font-size: ${(props) => props.theme.fontSize.small};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.small};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.base};
  }
  font-weight: ${(props) => props.theme.fontWeight.bold};
  display: flex;
  padding: 0.6em 0;
  transition: background 0.2s ease-in-out;
  align-items: center;

  &:hover {
    background-color: rgba(0, 0, 0, 0.06);
  }
`
const StyledIcon = styled(Icon)`
  color: ${(props) => props.theme.color.textTertiary};
  font-size: 1.5em;
  vertical-align: middle;
  margin-right: 0.5em;
  width: 1.5em;

  svg {
    margin: auto;
  }
`

const ItemSpecialNav = ({ children, url, icon, ...props }) => (
  <Wrapper to={url} {...props}>
    {icon && <StyledIcon icon={icon} />}
    {children}
  </Wrapper>
)

ItemSpecialNav.propTypes = {
  url: PropTypes.string,
  icon: Icon.propTypes.icon,
}

export default ItemSpecialNav
export { StyledIcon as StyledIcon0ItemSpecialNav }
