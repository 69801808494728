import React, { Fragment, useContext, useEffect, useReducer, useRef, useState } from 'react'
import styled, { css, keyframes, withTheme } from 'styled-components'
import NavPrimary from './components/NavPrimary'
import InputSearchHeader from './components/InputSearchHeader'
import InputMobileSearchHeader from './components/InputMobileSearchHeader'
import NavHouse from './components/NavHouse'
import NavTools from './components/NavTools'
import MoreNav from './components/MoreNav'
import StolLogoHeader from './components/StolLogoHeader'
import DistrictsNav from '../../../../components/DistrictsNav'
import Hamburger from '../../../../components/Hamburger'
import Icon from '../../../../components/Icon'
import ResizeDetector from '../../../../components/ResizeDetector'
import Sticker from 'react-stickyfill'
import { withRouter } from 'react-router-dom'
import IconNewsFeed from './components/IconNewsFeed'
import { LinkNav0ItemNavPrimary } from './components/NavPrimary/components/ItemNavPrimary'
import UpScroll from '../UpScroll'
import isStolApp from '../../../../modules/is-stolapp'
import SvgSplus from '../../../../components/Icon/keys/iconKeys/images/Splus'
import LinkNavTool from './components/NavTools/components/LinkNavTool'
import isServerSide from '../../../../modules/is-server-side'
import ContextMenu from '../../../../components/ContextMenu'
import routes from '../../../../topics/routes'
import StagingConfigTemplate from '../StagingConfigTemplate'
import useScrollDirection from '../../../../modules/use-scroll-direction'

const StyledStagingConfigTemplate = styled(StagingConfigTemplate)`
  position: fixed;
  left: 0;
  bottom: 100px;
  background-color: white;
  padding: 2px;
  border-radius: 10px;
  @media (max-width: 600px) {
    left: unset;
    right: 0;
  }
`

const ColHeader = styled.div``
const TopColHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const FirstTopCol = styled.div``

const SecondTopCol = styled(FirstTopCol)`
  flex: 2;
  justify-content: flex-end;
  min-width: 120px;
  max-width: 215px;
`
//IMPORTANT change max-width, min-width if you want to add Icons to the NavTools
const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const Wrapper = styled.header`
  top: 0;
  z-index: 5;
  position: sticky;
  /*stolLogo styles*/

  #stolLogo {
    height: 100px;
    width: 190px;
    overflow: hidden;
    display: block;
    box-sizing: border-box;
    transition: all 0.4s ease;
  }

  #stolLogoScroll {
    overflow: hidden;
    display: block;
    box-sizing: border-box;
    padding-right: ${(props) => props.theme.sizes.gapColHalf};
    backface-visibility: hidden;
    height: 55px;
    width: 120px;
    transition: all 0.4s ease;
  }

  /*mainWrapper styles*/

  .mainWrapperScroll {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
    background-color: ${(props) => props.theme.color.backgroundContent};
    padding: ${(props) => props.theme.sizes.gapColHalf} ${(props) => props.theme.sizes.gapEdge};
    display: flex;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-perspective: 1080px;
    -moz-perspective: 1080px;
    -ms-perspective: 1080px;
    perspective: 1080px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .mainWrapper {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
    background-color: ${(props) => props.theme.color.backgroundContent};
    padding: ${(props) => props.theme.sizes.gapColHalf} ${(props) => props.theme.sizes.gapEdge};
    display: flex;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-perspective: 1080px;
    -moz-perspective: 1080px;
    -ms-perspective: 1080px;
    perspective: 1080px;
  }

  @media (max-width: 1080px) {
    z-index: 2000;
    .mainWrapper {
      align-items: center;
      padding: 10px ${(props) => props.theme.mobile.sizes.gapEdge};
    }

    .mainWrapperScroll {
      align-items: center;
      padding: 10px ${(props) => props.theme.mobile.sizes.gapEdge};
    }

    #stolLogoWrapper {
      flex: 4;
    }

    #stolLogo {
      width: auto;
      height: 50px;
    }

    #stolLogoScroll {
      width: auto;
      height: 50px;
    }
  }

  @media (max-width: 1300px) {
    ${(props) =>
      props.hide &&
      css`
        visibility: hidden;
        opacity: 1;
        animation: ${fadeIn} 2s linear;
      `}
  }

  @media (max-width: 800px) {
    #stolLogo {
      height: 40px;
    }

    #stolLogoScroll {
      height: 40px;
    }

    .mainWrapper {
      padding: 10px ${(props) => props.theme.mobile.sizes.gapEdge};
    }

    .mainWrapperScroll {
      padding: 10px ${(props) => props.theme.mobile.sizes.gapEdge};
    }
  }
  @media (max-width: 500px) {
    #stolLogo {
      width: auto;
    }

    #stolLogoScroll {
      width: auto;
    }
  }

  /*navTools styles*/

  .navTools {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .navToolsScroll {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  /*firstTopCol styles*/

  .firstTopCol {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 5;
  }

  @media (min-width: 1080px) {
    .firstTopColScroll {
      display: none;
    }
  }

  /*colHeader styles*/

  .colHeaderScroll {
    align-items: center;
    padding-left: ${(props) => props.theme.sizes.gapColHalf};
    flex: 1;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;

    ${LinkNav0ItemNavPrimary} {
      font-size: ${(props) => props.theme.fontSize.small};
    }
  }

  .colHeader {
    padding-left: ${(props) => props.theme.sizes.gapCol};
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  @media (max-width: 1080px) {
    .colHeader {
      padding-left: ${(props) => props.theme.mobile.sizes.gapCol};
    }

    .colHeaderScroll {
      padding-left: ${(props) => props.theme.mobile.sizes.gapCol};
    }
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    .colHeader {
      padding-left: ${(props) => props.theme.mobile.sizes.gapCol};
    }

    .colHeaderScroll {
      padding-left: ${(props) => props.theme.mobile.sizes.gapCol};
    }
  }
  @media (max-width: 1080px) {
    .colHeader {
      display: none;
    }

    .colHeaderScroll {
      display: none;
    }
  }
  @media print {
    .colHeader {
      display: none;
    }

    .colHeaderScroll {
      display: none;
    }
  }
`
const MainWrapper = styled.div``

const subNavStyles = css`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
`
const HamburgerWrapper = styled.div`
  display: none;
  @media (max-width: 1080px) {
    display: block;
  }
  @media print {
    display: none;
  }
`
const iconStyle = css`
  display: none;
  @media (max-width: 1080px) {
    display: block;
    flex: 0.3;
  }
  @media (max-width: 800px) {
    flex: 0.5;
  }
  @media print {
    display: none;
  }
`
const iconSize = css`
  font-size: 2.5em;

  @media (max-width: 500px) {
    display: flex;
    font-size: 1.8em;
    justify-content: flex-end;
  }
`
const StyledIcon = styled(Icon)`
  color: ${(props) => props.theme.color.textSecondary};
  ${iconStyle}
  ${iconSize}

  cursor: pointer;
`

const StyledIconNewsFeed = styled(IconNewsFeed)`
  i {
    ${iconSize}
  }

  margin-left: 1.55em;
  margin-right: 1.55em;
  @media (max-width: 500px) {
    margin-left: 0.9em;
    margin-right: 0.9em;
  }
`

const MoreNavWrapper = styled.div`
  min-width: 100%;
  overflow-x: hidden;
  ${(props) =>
    props.dropDownMaxHeight &&
    props.open &&
    css`
      position: absolute;
      @media (max-width: 700px) {
        position: relative;
        display: block;
        overflow: scroll;
        overflow-x: hidden;
        height: ${(props) => props.dropDownMaxHeight + 'px'};
      }
    `}
`
const StyledSplus = styled(SvgSplus)`
  align-self: center;

  height: 2.5em;
  width: 2.5em;

  @media (max-width: 500px) {
    display: flex;
    height: 1.8em;
    width: 1.8em;
    justify-content: flex-end;
  }
`

const StyledMobileNavFlex = styled.div`
  width: 28%;
  display: none;

  @media (max-width: 1080px) {
    display: flex;
    justify-content: flex-end;
  }
`
const StyledMoreNav = styled(MoreNav)`
  ${subNavStyles};
  overflow-x: hidden;
  @media (max-width: 1080px) {
    top: 0;
    position: relative;
  }
`

const StyledDistrictsNav = styled(DistrictsNav)`
  ${subNavStyles};
`

const Header = (props) => {
  const menuRef = useRef()
  const initialState = {
    dropDownOpen: null,
    showMobileSearch: false,
    mainWrapperMinHeight: undefined,
    menuHeight: undefined,
    dropDownMaxHeight: undefined,
    submitSearch: false,
  }

  const reducer = (state, action) => {
    switch (action.type) {
      case 'submitMobileSearch':
        return { ...state, submitSearch: true }
      case 'showMobileSearch':
        return {
          ...state,
          mainWrapperMinHeight:
            menuRef &&
            menuRef.current &&
            menuRef.current.getBoundingClientRect().height - 2 * props.theme.sizes.gapColHalf.replace('px', ''),
          showMobileSearch: true,
          dropDownOpen: undefined,
        }
      case 'hideMobileSearch':
        return { ...state, showMobileSearch: false }
      case 'closeDropDown':
        return { ...state, dropDownOpen: undefined }
      case 'openHamburgerMenu':
        return {
          ...state,
          dropDownOpen: 'more',
          dropDownMaxHeight: window.innerHeight - menuRef.current.getBoundingClientRect().bottom + 15,
        }
      case 'hideHamburgerMenu':
        return {
          ...state,
          dropDownOpen: undefined,
          showMobileSearch: false,
        }
      case 'selectNavPointer':
        const positionOpen = state.dropDownOpen === action.position ? null : action.position
        return { ...state, dropDownOpen: positionOpen }
      default:
        throw new Error()
    }
  }
  const [state, dispatch] = useReducer(reducer, initialState)
  const scrollDirection = useScrollDirection()
  const scrollDown = scrollDirection === 'down'

  const toggleSearchInputMobile = () => {
    if (!isServerSide()) {
      document.body.style.overflow = 'auto'
    }
    if (state.showMobileSearch) {
      dispatch({ type: 'submitMobileSearch' })
    }
    if (!state.showMobileSearch) {
      dispatch({
        type: 'showMobileSearch',
      })
    }
  }

  const closeDropDown = () => {
    dispatch({ type: 'closeDropDown' })
  }

  const selectNavPointHandler = (position) => {
    dispatch({ type: 'selectNavPointer', position })
  }

  useEffect(() => {
    if (!isServerSide()) {
      document.getElementsByTagName('body')[0].style.overflow = 'unset'
      if (state.dropDownOpen || state.showMobileSearch) {
        if (menuRef && menuRef && menuRef.current.getBoundingClientRect().width < 700) {
          document.getElementsByTagName('body')[0].style.overflow = 'hidden'
        }
      }
      if (
        state.dropDownOpen !== null &&
        state.dropDownOpen !== undefined &&
        menuRef.current.getBoundingClientRect().width > 700
      ) {
        document.addEventListener('click', closeDropDown)
      }
      if (state.dropDownOpen === null || state.dropDownOpen === undefined) {
        document.removeEventListener('click', closeDropDown)
      }
    }

    if (state.dropDownOpen !== undefined) {
      dispatch({ type: 'closeDropDown' })
    }
  }, [])

  const menuData = useContext(ContextMenu)
  let menu = menuData.responseData

  let navigationStructure = []
  let position = 0
  menu.primary.map((menuItem, index) => {
    position = position + 1
    navigationStructure.push({
      position: index, //TODO README PLEASE - "position" was set to fix a bug. Its functionality is NOT CLEAR!
      name: menuItem.name,
      url: menuItem.url,
      type: menuItem.submenu ? 'multiple' : menuItem.districts ? 'districts' : 'single',
    })
    return null
  })
  navigationStructure.push({
    position: position + 1,
    name: 'Mehr',
    type: 'more',
  })

  let logoUrl = null
  const logoData = menuData.responseData.meta.logo
  if (logoData.active) {
    logoUrl = logoData.url
  }

  return (
    <Sticker>
      <Wrapper hide={!isServerSide() && scrollDown && !state.dropDownOpen} {...props}>
        <ResizeDetector
          onResize={(boundingClientRect) => {
            if (boundingClientRect.width >= 1080) {
              dispatch({ type: 'hideMobileSearch' })
            }
          }}
        >
          <MainWrapper ref={menuRef} className="mainWrapper">
            <HamburgerWrapper>
              <Hamburger
                callbackopen={() => {
                  if (!isServerSide()) {
                    dispatch({ type: 'openHamburgerMenu' })
                  }
                }}
                callbackclose={() => {
                  dispatch({ type: 'hideHamburgerMenu' })
                }}
                isactive={state.showMobileSearch}
              />
            </HamburgerWrapper>
            {state.showMobileSearch && <InputMobileSearchHeader submit={state.submitSearch} />}
            {!state.showMobileSearch && (
              <Fragment>
                <StolLogoHeader img={logoUrl} />
                <StyledMobileNavFlex>
                  <LinkNavTool to={routes.getOptions('department.splus').path}>
                    <StyledSplus />
                  </LinkNavTool>
                  <StyledIconNewsFeed />
                  <StyledIcon onClick={toggleSearchInputMobile} icon="search" />
                </StyledMobileNavFlex>
              </Fragment>
            )}

            <ColHeader className="colHeader">
              <TopColHeader>
                <FirstTopCol className="firstTopCol">
                  <InputSearchHeader />
                  <NavHouse items={menu.top} />
                </FirstTopCol>
                <SecondTopCol>
                  <NavTools className="navTools" />
                </SecondTopCol>
              </TopColHeader>
              <NavPrimary
                navigationStructure={navigationStructure}
                selectNavPoint={selectNavPointHandler}
                openNavPoint={state.dropDownOpen}
              />
            </ColHeader>
          </MainWrapper>
        </ResizeDetector>
        <MoreNavWrapper dropDownMaxHeight={state.dropDownMaxHeight} open={state.dropDownOpen}>
          <StyledMoreNav
            closeDropdownCallback={() => {
              closeDropDown()
            }}
            open={state.dropDownOpen === 'more'}
          />
        </MoreNavWrapper>
        <StyledDistrictsNav
          onMouseLeaveCallBack={() => {
            dispatch({ type: 'closeDropDown' })
          }}
          open={state.dropDownOpen === 'districts'}
        />
        {(state.dropDownOpen === undefined || state.dropDownOpen === null) && !isStolApp() && <UpScroll />}
        {process.env.RAZZLE_SYSTEM !== 'live' && !isStolApp() && <StyledStagingConfigTemplate />}
      </Wrapper>
    </Sticker>
  )
}

export default withRouter(withTheme(Header))
