import React, { Fragment, useContext } from 'react'
import styled from 'styled-components'
import translate from '../../../../../../modules/translate'
import WeatherIcon from '../../../../../../topics/Weather/components/IconWeather/images/icon_c.svg'
import LinkNavTool from './components/LinkNavTool'
import LoginNavTool from './components/LoginNavTool'
import SvgSplus from '../../../../../../components/Icon/keys/iconKeys/images/Splus'
import Delimiter from './components/Delimiter'
import Icon from '../../../../../../components/Icon'
import ContextMenu from '../../../../../../components/ContextMenu'
import IconWeather from '../../../../../../topics/Weather/components/IconWeather'
import { useUserContext } from '../../../../../../components/ContextUser'
import routes from '../../../../../../topics/routes'

const Wrapper = styled.div`
  display: flex;
  color: ${(props) => props.theme.color.textSecondary};
  font-size: ${(props) => props.theme.fontSize.small};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.small};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.small};
  }
  align-items: center;
`

const WeatherLabel = styled.span`
  .mainWrapperScroll & {
    display: none;
  }
`

const StyledIconWeather = styled(IconWeather)`
  height: 1.2em;
  width: 2.4em;
  min-width: 35px;
`

const StyledSplus = styled(SvgSplus)`
  height: 21px;
  width: 21px;
`

const StyledHrefLink = styled.a`
  text-decoration: none;
  color: ${(props) => props.theme.color.textPrimary};
`
const StyledIcon = styled(Icon)`
  font-size: 1.4em;
`
//IMPORTANT change max-width, min-width (SecondTopCol in Header/index.js) if you want to add Icons to the NavTools

const NavTools = ({ ...props }) => {
  const menuData = useContext(ContextMenu)
  const { profile } = useUserContext()

  let RelevantWeatherIcon = WeatherIcon
  if (
    menuData.responseData &&
    menuData.responseData.meta &&
    menuData.responseData.meta.weather &&
    menuData.responseData.meta.weather.icon
  ) {
    RelevantWeatherIcon = menuData.responseData.meta.weather.icon
  }
  RelevantWeatherIcon = RelevantWeatherIcon.replace('/images/icon_', '').replace('.svg', '')

  return (
    <Wrapper {...props}>
      <LinkNavTool to={routes.getOptions('weather').path}>
        <StyledIconWeather name={RelevantWeatherIcon} square />
      </LinkNavTool>
      <LoginNavTool isLoggedIn={!!profile} />
      <LinkNavTool to={routes.getOptions('department.splus').path}>
        <StyledSplus />
      </LinkNavTool>
      {profile && profile.roles && profile.roles.includes('backend') && (
        <Fragment>
          <Delimiter />
          <StyledHrefLink
            href={
              (process.env.RAZZLE_ENVIRONMENT === 'production' ? 'https://www.stol.it' : 'https://re.stol.it') +
              routes.getOptions('backend').path
            }
          >
            <StyledIcon icon="exit_to_app" />
          </StyledHrefLink>
        </Fragment>
      )}
    </Wrapper>
  )
}

export default NavTools
export { Wrapper as Wrapper0NavTools }
