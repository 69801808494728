import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Link from '../../../../../../components/Link'

const Wrapper = styled.nav`
  display: flex;
  color: ${(props) => props.theme.color.textSecondary};
  font-size: ${(props) => props.theme.fontSize.small};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.small};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.small};
  }
`

const StyledLink = styled(Link)`
  margin: 0 0.5em;
  color: ${(props) => props.theme.color.textSecondary};
  font-size: ${(props) => props.theme.fontSize.small};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.small};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.small};
  }
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
`
const Delimiter = styled.em`
  font-style: normal;
  cursor: default;
  color: ${(props) => props.theme.color.textSecondary};
  font-size: ${(props) => props.theme.fontSize.small};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.small};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.small};
  }
`

const DisplayNavHouse = ({ items, ...props }) => {
  const links = []
  items.forEach(({ name, url }, index) => {
    links.push(
      <StyledLink key={name + url + index} to={url}>
        {name}
      </StyledLink>,
    )

    if (items.length - 1 !== index) {
      links.push(<Delimiter key={name + url + index + 0.5}>|</Delimiter>)
    }
  })
  return <Wrapper {...props}>{links}</Wrapper>
}

DisplayNavHouse.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ),
}

DisplayNavHouse.defaultProps = {
  items: [],
}

export default DisplayNavHouse
export { Wrapper as Wrapper0NavHouse }
