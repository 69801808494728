import React from 'react'
import styled from 'styled-components'
import Link from '../../../../components/Link'
import googleIcon from '../../images/en_badge_web_generic.png'
import appleIcon from '../../images/apple-store-badge.png'
import FALogo from '../../../../images/FALogo'

const Wrapper = styled.div`
  flex: 1;
  margin-bottom: auto;
  text-align: center;
  margin-top: 20px;
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    display: contents;
  }
`

const StyledFALogo = styled(FALogo)`
  width: calc(100% - 30px);
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  margin-left: 15px;
  margin-right: 15px;
  @media (max-width: 660px) {
    margin-top: 20px;
    margin-bottom: 10px;
  }
`

const StyledLogo = styled.img`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`

const StyledLink = styled(Link)`
  width: 250px;
  @media (max-width: 820px) {
    width: 200px;
  }
  @media (max-width: 660px) {
    width: 250px;
  }
`

const ImportantLinksContainer = ({ ...props }) => (
  <Wrapper {...props}>
    <StyledLink to="https://www.firstavenue.it/newsportale">
      {/* TODO check how to remove the hardcoded link */}
      <StyledFALogo />
    </StyledLink>
    <StyledLink to="https://play.google.com/store/apps/details?id=com.keepinmind.stol_mobile">
      {/* TODO check how to remove the hardcoded link */}
      <StyledLogo src={googleIcon} />
    </StyledLink>
    <StyledLink to="https://apps.apple.com/it/app/stol-it/id427788166">
      {/* TODO check how to remove the hardcoded link */}
      <StyledLogo src={appleIcon} />
    </StyledLink>
  </Wrapper>
)

ImportantLinksContainer.propTypes = {}

ImportantLinksContainer.defaultProps = {}

export default ImportantLinksContainer
