import React from 'react'
import styled from 'styled-components'
import MoreNav from '../Header/components/MoreNav'

const StyledMoreNav = styled(MoreNav)`
  background-color: #fff;
  position: relative;
  @media print {
    display: none;
  }
`

const Sitemap = (props) => <StyledMoreNav open {...props} />

export default Sitemap
