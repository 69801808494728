import React, { Fragment } from 'react'
import Delimiter from '../Delimiter'
import Icon from '../../../../../../../../components/Icon'
import styled from 'styled-components'
import LinkNavTool from '../LinkNavTool'
import routes from '../../../../../../../../topics/routes'

const Wrapper = Fragment
const StyledIcon = styled(Icon)`
  font-size: 1.4em;
  color: ${(props) => (props.isUserLoggedIn ? props.theme.color.stol : props.theme.color.secondaryColor)};
`

const LoginNavTool = ({ isLoggedIn, ...props }) => (
  <Wrapper {...props}>
    <Delimiter />
    <LinkNavTool to={isLoggedIn ? routes.getOptions('account').path : routes.getOptions('login').path}>
      <StyledIcon isUserLoggedIn={isLoggedIn} icon="person" />
    </LinkNavTool>
    <Delimiter />
  </Wrapper>
)

export default LoginNavTool
