import React from 'react'

const FALogo = ({ ...props }) => (
  <svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1735 477.9" {...props}>
    <defs>
      <style>{'.fa_logo_svg{fill:#19162A;}'}</style>
    </defs>
    <g>
      <path
        className="fa_logo_svg"
        d="M1086.2,158.8h-0.5c-4.4,0-8.2,2.8-9.6,7L1038.3,283c-1.2,3.6-6.2,3.6-7.4,0l-43.1-117.2
		c-1.4-4.1-5.2-6.9-9.6-7l-11.7,0c-4.5,0-8.5,2.8-10.1,7l-53.8,137.9c-2.7,7.1,2.6,14.6,10.1,14.6h0.2c4,0,7.7-2.5,9-6.4l11.9-27.6
		c1.5-4.3,5.6-7.2,10.2-7.2h28.3c5.1,0,9.7-3.8,10-8.9c0.3-5.4-4-10-9.4-10h-24.1c-2.7,0-4.6-2.7-3.7-5.2l17.1-42.7
		c3.6-10,16-10,19.6,0l21.7,59.3l0.4,0.8l13.8,39.7c1.6,4.6,5.9,7.6,10.7,7.6h11.1c4.9,0,9.2-3.1,10.7-7.7l46.8-136.2
		C1099.5,166.5,1094,158.8,1086.2,158.8z"
      />
      <path
        className="fa_logo_svg"
        d="M426.4,158.9h-0.7h-2h-105c-5.6,0-10.1,4.5-10.1,10.1v139.2c0,5.6,4.5,10.1,10.1,10.1h2.6
		c5.6,0,10.1-4.5,10.1-10.1v-51.8c0-5.6,4.5-10.1,10.1-10.1h24c5.4,0,10.1-4.1,10.4-9.4c0.4-5.9-4.3-10.8-10.1-10.8h-24.4
		c-5.6,0-10.1-4.5-10.1-10.1v-26.9c0-5.6,4.5-10.1,10.1-10.1h62.1c5.5,0,10,4.5,10,10v119.1c0,5.6,4.5,10.1,10.1,10.1h2.6
		c5.6,0,10.1-4.5,10.1-10.1V169C436.5,163.4,431.9,158.9,426.4,158.9z"
      />
      <g>
        <path
          className="fa_logo_svg"
          d="M527.8,258.3c-1.8-3.4-5.3-5.4-9.1-5.4c-2.2,0-4.4,0-6.6,0h-16.6c-5.6,0-10.1,4.5-10.1,10.1v45.1
			c0,5.6-4.5,10.1-10.1,10.1h-2.6c-5.6,0-10.1-4.5-10.1-10.1V169c0-5.6,4.5-10.1,10.1-10.1h39.4c39.4,0,67.2,7.9,67.2,46.6
			c0,20.8-8.1,33-21.5,39.9c-5.1,2.6-7.1,8.7-4.4,13.7l23.6,44.4c3.6,6.7-1.3,14.8-8.9,14.8h-2.6c-3.7,0-7.2-2.1-8.9-5.4
			L527.8,258.3z M485.5,189.1v33.6c0,5.6,4.5,10.1,10.1,10.1h18.2c23.8,0,42.2-1.9,42.2-26.9c0-25-18.5-26.9-42.2-26.9h-18.2
			C490,179,485.5,183.5,485.5,189.1z"
        />
      </g>
      <g>
        <path
          className="fa_logo_svg"
          d="M653.5,158c24.4,0,46.6,9.5,56.8,29.7c3.1,6.1-0.8,13.5-7.6,14.5l-2,0.3c-4.4,0.6-8.5-1.7-10.5-5.6
			c-7.3-14.5-23.2-19.2-37.5-19.2c-12.5,0-34.3,5-34.3,23.3c0,14.2,11.3,20.4,26.4,23.8l18.7,4.1c24.7,5.5,54.2,14.2,54.2,46.6
			c0,31.7-29.3,47.8-61.4,47.8c-33,0-55.4-15.6-63.7-39.4c-2.1-6,1.8-12.4,8.1-13.3l2.4-0.3c4.7-0.7,9.3,2.1,10.9,6.6
			c6.1,17.2,20.3,26.7,42.8,26.7c23.8,0,37.7-10.1,37.7-25.9c0-17-14.6-23.5-34.8-27.8l-20.2-4.3c-22.1-4.8-44.4-16.6-44.4-43.7
			C594.9,171,627.3,158,653.5,158z"
        />
      </g>
      <g>
        <path
          className="fa_logo_svg"
          d="M757.9,183h-29.4c-5.6,0-10.1-4.5-10.1-10.1v0c0-5.6,4.5-10.1,10.1-10.1h101.8c5.6,0,10.1,4.5,10.1,10.1v0
			c0,5.6-4.5,10.1-10.1,10.1h-29.4c-5.6,0-10.1,4.5-10.1,10.1v117c0,5.6-4.5,10.1-10.1,10.1h-2.6c-5.6,0-10.1-4.5-10.1-10.1v-117
			C768,187.5,763.5,183,757.9,183z"
        />
      </g>
      <g>
        <path
          className="fa_logo_svg"
          d="M1119.7,158.9h89.5c5.6,0,10.1,4.5,10.1,10.1v0c0,5.6-4.5,10.1-10.1,10.1h-66.7c-5.6,0-10.1,4.5-10.1,10.1
			V216c0,5.6,4.5,10.1,10.1,10.1h61.7c5.6,0,10.1,4.5,10.1,10.1v0c0,5.6-4.5,10.1-10.1,10.1h-61.7c-5.6,0-10.1,4.5-10.1,10.1V288
			c0,5.6,4.5,10.1,10.1,10.1h66.7c5.6,0,10.1,4.5,10.1,10.1v0c0,5.6-4.5,10.1-10.1,10.1h-89.5c-5.6,0-10.1-4.5-10.1-10.1V169
			C1109.7,163.4,1114.2,158.9,1119.7,158.9z"
        />
      </g>
      <g>
        <path
          className="fa_logo_svg"
          d="M1330.5,313l-61.7-113.1c-2.5-4.5-9.4-2.8-9.4,2.4v105.9c0,5.6-4.5,10.1-10.1,10.1h-2.6
			c-5.6,0-10.1-4.5-10.1-10.1V169c0-5.6,4.5-10.1,10.1-10.1h17.3c3.7,0,7.1,2,8.8,5.2l61.7,112.9c2.5,4.5,9.4,2.8,9.4-2.4V169
			c0-5.6,4.5-10.1,10.1-10.1h2.6c5.6,0,10.1,4.5,10.1,10.1v139.2c0,5.6-4.5,10.1-10.1,10.1h-17.3
			C1335.7,318.2,1332.3,316.2,1330.5,313z"
        />
      </g>
      <g>
        <path
          className="fa_logo_svg"
          d="M1403,158.9c5.6,0,10.1,4.5,10.1,10.1v84.9c0,31.7,11.3,46.6,41.8,46.6c31,0,42.2-17,42.2-49V169
			c0-5.6,4.5-10.1,10.1-10.1h2.6c5.6,0,10.1,4.5,10.1,10.1v80.6c0,47.3-18,70.6-65,70.6c-44.9,0-64.6-18.2-64.6-68.6V169
			c0-5.6,4.5-10.1,10.1-10.1H1403z"
        />
      </g>
      <g>
        <path
          className="fa_logo_svg"
          d="M1551.7,158.9h89.5c5.6,0,10.1,4.5,10.1,10.1v0c0,5.6-4.5,10.1-10.1,10.1h-66.7c-5.6,0-10.1,4.5-10.1,10.1
			V216c0,5.6,4.5,10.1,10.1,10.1h61.7c5.6,0,10.1,4.5,10.1,10.1v0c0,5.6-4.5,10.1-10.1,10.1h-61.7c-5.6,0-10.1,4.5-10.1,10.1V288
			c0,5.6,4.5,10.1,10.1,10.1h66.7c5.6,0,10.1,4.5,10.1,10.1v0c0,5.6-4.5,10.1-10.1,10.1h-89.5c-5.6,0-10.1-4.5-10.1-10.1V169
			C1541.7,163.4,1546.2,158.9,1551.7,158.9z"
        />
      </g>
    </g>
    <path
      className="fa_logo_svg"
      d="M213.8,132.3H94.7c-5.5,0-10,4.5-10,10v192c0,5.5,4.5,10,10,10h119.1c5.5,0,10-4.5,10-10v-192
	C223.8,136.8,219.3,132.3,213.8,132.3z M196.2,257.8v55.7c0,2.6-2.1,4.6-4.6,4.6h-17.3c-2.6,0-4.6-2.1-4.6-4.6v-55.7c0,0,0,0,0,0
	v-49.3v-39.4c0-2.6-2.1-4.6-4.6-4.6c-1,0-2,0-2.8,0h-1.4h-13.1h-4.2c-2.6,0-4.6,2.1-4.6,4.6v46.6c0,2.6,2.1,4.6,4.6,4.6h12.2
	c2.6,0,4.6,2.1,4.6,4.6v18.6c0,2.6-2.1,4.6-4.6,4.6h-12.2c-2.6,0-4.6,2.1-4.6,4.6v60.7c0,2.6-2.1,4.6-4.6,4.6h-17.3
	c-2.6,0-4.6-2.1-4.6-4.6V163.4c0-2.6,2.1-4.6,4.6-4.6h30.8h13.1h2.1c0.6,0,1.3,0,2,0h4.6h21.9c2.6,0,4.6,2.1,4.6,4.6V257.8z"
    />
  </svg>
)

export default FALogo
