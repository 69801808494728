import React, { Component } from 'react'
import styled from 'styled-components'
import translate from '../../../../../../modules/translate'
import Input, { Input0Input } from '../../../../../../components/Input'
import { withRouter } from 'react-router-dom'
import isServerSide from '../../../../../../modules/is-server-side'

const Wrapper = styled.div`
  max-width: 100%;
  align-items: center;
  flex: 4;
  display: none;
  @media (max-width: 1080px) {
    display: block;
  }
`

const StyledInput = styled(Input)`
  border-bottom: 0;

  ${Input0Input} {
    font-size: 2em;
    padding: 0;
  }
`

class InputMobileSearchHeader extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searchTerm: '',
      submit: false,
    }
    this.handleSearchTermChange = this.handleSearchTermChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.submit !== this.props.submit) {
      this.handleSubmit()
    } else {
      this.setState({
        submit: false,
      })
    }
  }
  handleSearchTermChange(event) {
    this.setState({
      searchTerm: event.target.value,
    })
  }
  handleSubmit() {
    this.props.history.push('/suche/' + this.state.searchTerm)
    if (!isServerSide()) {
      window.location.reload()
    }
  }
  render() {
    const { props } = this.props
    return (
      <Wrapper {...props}>
        <form onSubmit={this.handleSubmit} style={{ width: '100%' }}>
          <StyledInput
            autoFocus
            value={this.state.searchTerm}
            onChange={this.handleSearchTermChange}
            type="search"
            placeholder={translate('search_placeholder')}
          />
        </form>
      </Wrapper>
    )
  }
}

InputMobileSearchHeader.defaultProps = {
  submit: false,
}

export default withRouter(InputMobileSearchHeader)
