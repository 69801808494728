import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  padding-left: ${(props) => props.theme.sizes.gapEdge};
  padding-right: ${(props) => props.theme.sizes.gapEdge};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    padding-left: ${(props) => props.theme.tablet.sizes.gapEdge};
    padding-right: ${(props) => props.theme.tablet.sizes.gapEdge};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    padding-left: ${(props) => props.theme.mobile.sizes.gapEdge};
    padding-right: ${(props) => props.theme.mobile.sizes.gapEdge};
  }
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
`

const Col = styled.div`
  background-color: rgba(0, 255, 255, 0.2);
  width: ${(props) => props.theme.sizes.col2};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    width: ${(props) => props.theme.tablet.sizes.col2};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    width: ${(props) => props.theme.mobile.sizes.col2};
  }
`

const GridLayout = ({ ...props }) => (
  <Wrapper {...props}>
    {[...Array(6)].map((k, index) => (
      <Col key={'gridLayoutCol' + index} />
    ))}
  </Wrapper>
)

export default GridLayout
