import React, { memo } from 'react'
import styled, { css } from 'styled-components'
import Text from '../Text'
import AdblockMessage from './images/check.gif'
import translate from '../../modules/translate'
import ReactGA from 'react-ga'
import Image from '../Image'
import { createUuid } from '../../modules/uuid'
import useDetectAdBlock from '../../modules/use-detect-ad-block'
import pushEventToDataLayer from '../../modules/push-event-to-data-layer'
import images from './images/index'
import Link from '../Link'

const Wrapper = styled.div`
  width: ${(props) => props.theme.breakPoint + 'px'};
  display: flex;
  z-index: 0;
  flex-direction: row;
  background-color: rgba(255, 255, 255);
  padding-bottom: ${(props) => props.theme.sizes.gapVerticalHalf};
  padding-top: ${(props) => props.theme.sizes.gapVerticalHalf};
  background-color: white;
  margin-bottom: calc(${(props) => props.theme.sizes.gapVerticalHalf} - 150px);
  align-self: center;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 600 + 'px'}) {
    align-self: baseline;
    margin-left: calc((100% - 1080px - 160px - 300px) / 2 + 160px);
  }
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 460 + 'px'}) {
    align-self: baseline;
    margin-left: calc((100% - 1080px - 300px) / 2);
  }
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 300 + 'px'}) {
    align-self: center;
    margin-left: 0;
  }
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    width: 100%;
    padding-bottom: ${(props) => props.theme.tablet.sizes.gapVerticalHalf};
    padding-top: ${(props) => props.theme.tablet.sizes.gapVerticalHalf};
  }
  @media (max-width: ${(props) => props.theme.tablet.breakPoint - 61 + 'px'}) {
    margin-bottom: ${(props) => props.theme.tablet.sizes.gapVerticalHalf};
  }
  @media (max-width: 820px) {
    flex-direction: column;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    padding-bottom: ${(props) => props.theme.mobile.sizes.gapVerticalHalf};
    padding-top: ${(props) => props.theme.mobile.sizes.gapVerticalHalf};
    margin-bottom: ${(props) => props.theme.mobile.sizes.gapVerticalHalf};
  }
  -webkit-box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.35);
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.35);
`

const LeftWrapper = styled.div`
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  display: flex;
  padding-right: 0;
  padding-left: ${(props) => props.theme.sizes.gapEdge};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    padding-left: ${(props) => props.theme.tablet.sizes.gapEdge};
  }
  @media (max-width: 820px) {
    padding-right: ${(props) => props.theme.tablet.sizes.gapEdge};
    margin-bottom: 10px;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    padding-right: ${(props) => props.theme.mobile.sizes.gapEdge};
    padding-left: ${(props) => props.theme.mobile.sizes.gapEdge};
  }
`

const RightWrapper = styled.div`
  flex: 1.5;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  display: flex;
  padding-right: ${(props) => props.theme.sizes.gapEdge};
  padding-left: ${(props) => props.theme.sizes.gapEdge};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    padding-right: ${(props) => props.theme.tablet.sizes.gapEdge};
    padding-left: ${(props) => props.theme.tablet.sizes.gapEdge};
  }
  @media (max-width: 935px) {
    flex: 1.3;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    padding-right: ${(props) => props.theme.mobile.sizes.gapEdge};
    padding-left: ${(props) => props.theme.mobile.sizes.gapEdge};
  }
`

const StyledText = styled(Text)`
  margin-top: 10px;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.small};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.small};
  }
  ${(props) =>
    props.br &&
    css`
      margin-bottom: 10px;
    `}
`

const StyledImage = styled.div`
  margin: 10px 20px 5px;
  @media (max-width: 820px) {
    align-self: center;
  }
`

const StyledBrowserWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const StyledBrowserIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  margin-top: 10px;
`

const StyledBrowserLogo = styled.img`
  width: 32px;
`

const StyledLink = styled(Link)`
  display: flex;
  ${(props) =>
    props.br &&
    css`
      margin-bottom: 5px;
    `}
`

const AdBlockMessage = (props) => {
  // Disable the whole Logic - added Google Logic.
  return false

  const adBlockDetected = useDetectAdBlock()

  if (!adBlockDetected) {
    return false
  }

  ReactGA.event({ category: 'Adblock', action: 'show banner' })
  pushEventToDataLayer('AdBlockMessage')
  return (
    <Wrapper id={createUuid()} adsAreBlocked="true" {...props}>
      <LeftWrapper id={createUuid()}>
        <StyledText id={createUuid()} br={true}>
          {translate('adblock_message_1')}
        </StyledText>
        <StyledText id={createUuid()}>{translate('adblock_message_2')}</StyledText>
        <StyledText id={createUuid()}>{translate('adblock_message_3')}</StyledText>
      </LeftWrapper>
      <RightWrapper id={createUuid()}>
        <StyledText id={createUuid()}>{translate('adblock_title')}</StyledText>
        <StyledText id={createUuid()}>{translate('adblock_task')}</StyledText>
        <StyledImage id={createUuid()}>
          <Image id={createUuid()} width={350} height={170} src={AdblockMessage} />
        </StyledImage>
        <StyledBrowserWrapper>
          <StyledText id={createUuid()}>{translate('adblock_browser_settings')}</StyledText>
          <StyledBrowserIconWrapper>
            <StyledLink
              to={
                'https://support.mozilla.org/de/kb/verbesserter-schutz-aktivitatenverfolgung-desktop#w_was-konnen-sie-tun-wenn-eine-webseite-nicht-mehr-richtig-funktioniert'
              }
              br={true}
            >
              <StyledBrowserLogo id={createUuid()} src={images['firefox']} />
            </StyledLink>
            <StyledLink
              to={
                'https://support.microsoft.com/de-de/microsoft-edge/weitere-informationen-zur-tracking-verhinderung-in-microsoft-edge-5ac125e8-9b90-8d59-fa2c-7f2e9a44d869#:~:text=Sie%20k%C3%B6nnen%20eine,Dropdown%2DMen%C3%BC%20aus'
              }
            >
              <StyledBrowserLogo id={createUuid()} src={images['edge']} />
            </StyledLink>
          </StyledBrowserIconWrapper>
        </StyledBrowserWrapper>
      </RightWrapper>
    </Wrapper>
  )
}

export default memo(AdBlockMessage)
