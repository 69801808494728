import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import withErrorIcon from './modules/with-error-icon'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  ${(props) =>
    props.border &&
    css`
      border: 1px solid ${(props) => props.theme.color.textTertiary};
    `}
  border-bottom: 2px solid ${(props) => (props.focus ? props.theme.color.stol : props.theme.color.textTertiary)};
  transition: border-color 0.1s ease-in-out;
  font-size: ${(props) => props.theme.fontSize.base};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.base};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.base};
  }
  font-family: ${(props) => props.theme.font.primary};
  background-color: ${(props) => props.theme.color.backgroundContent};
`

const StyledInput = styled.input`
  font-size: inherit;
  font-family: inherit;
  border-width: 0;
  outline: none;
  ${(props) =>
    props.border || props.padding
      ? css`
          padding: 0.4em;
        `
      : css`
          padding: 0.4em 0;
        `}
  width: 100%;
  height: calc(100% - 1em);
  resize: none;

  &::placeholder {
    color: ${(props) => props.theme.color.textTertiary};
  }
`

class Input extends Component {
  constructor(props) {
    super(props)
    this.state = { focus: false }
  }
  render() {
    const { className, prepend, append, ...props } = this.props
    return (
      <Wrapper className={className} focus={this.state.focus} border={props.border}>
        {prepend}
        {this.getElement(props)}
        {append}
      </Wrapper>
    )
  }

  handleFocus = () => {
    this.setState({ focus: true })
  }

  handleBlur = () => {
    this.setState({ focus: false })
  }
  getElement = ({ type, onChange, border, padding, ...props }) => {
    switch (type) {
      case 'textarea':
        return (
          <StyledInput
            as="textarea"
            defaultValue={this.props.customValue && this.props.customValue}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            onChange={onChange}
            padding={padding}
            border={border}
            {...props}
          />
        )
      default:
        return (
          <StyledInput
            type={type}
            defaultValue={this.props.customValue && this.props.customValue}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            onChange={onChange}
            padding={padding}
            border={border}
            {...props}
          />
        )
    }
  }
}

Input.propTypes = {
  type: PropTypes.oneOf([
    'date',
    'email',
    'month',
    'number',
    'range',
    'search',
    'tel',
    'text',
    'textarea',
    'time',
    'url',
    'week',
    'password',
  ]),
  placeholder: PropTypes.string,
  prepend: PropTypes.node,
  append: PropTypes.node,
  border: PropTypes.bool,
  padding: PropTypes.bool,
}

Input.defaultProps = {
  type: 'text',
}

export default withErrorIcon(Input)
export { StyledInput as Input0Input }
