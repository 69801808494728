import React from 'react'
import styled from 'styled-components'
import Link from '../../../../components/Link'
import footerLinks from '../../../../config/data/footerLinks.json'
import { htmlParser } from '../../../../modules/parse'

const Wrapper = styled(({ open, ...props }) => <div {...props} />)`
  display: flex;
  justify-content: flex-end;
  padding: ${(props) => props.theme.sizes.gapVertical} ${(props) => props.theme.sizes.gapEdge};
  background-color: #fff;
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    padding: ${(props) => props.theme.tablet.sizes.gapVertical} ${(props) => props.theme.tablet.sizes.gapEdge};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    padding: ${(props) => props.theme.mobile.sizes.gapVertical} ${(props) => props.theme.mobile.sizes.gapEdge};
  }
  @media print {
    display: none;
  }
`
const StyledLink = styled(Link)`
  color: ${(props) => props.theme.color.textSecondary};
  padding-right: 5px;
  padding-top: 5px;
  &:hover {
    color: ${(props) => props.theme.color.stol};
  }
`

const MainNav = styled.div`
  width: calc(
    ${(props) => props.theme.sizes.col9} + (${(props) => props.theme.sizes.col2.replace('calc', '')}) + 10px +
      ${(props) => props.theme.sizes.gapCol}
  );
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    width: calc(
      ${(props) => props.theme.tablet.sizes.col9} + (${(props) => props.theme.tablet.sizes.col2.replace('calc', '')}) +
        10px + ${(props) => props.theme.tablet.sizes.gapCol}
    );
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    width: calc(
      ${(props) => props.theme.mobile.sizes.col9} + (${(props) => props.theme.mobile.sizes.col2.replace('calc', '')}) +
        10px + ${(props) => props.theme.mobile.sizes.gapCol}
    );
  }
`

const FirstLine = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const SecondLine = styled.div`
  display: flex;
  padding-top: 5px;
  flex-wrap: wrap;
`

const Footer = ({ ...props }) => {
  return (
    <Wrapper {...props}>
      <MainNav>
        <FirstLine>
          {footerLinks.firstLine.map((link, index) => {
            return (
              <StyledLink key={index + 'StyledLink0Footer'} autoExternalLinkIcon="force" to={link.value}>
                {htmlParser(link.label)}
              </StyledLink>
            )
          })}
        </FirstLine>
        {footerLinks.secondLine.length > 0 && (
          <SecondLine>
            {footerLinks.secondLine.map((link, index) => {
              return (
                <StyledLink key={index + 'StyledLink0Footer'} autoExternalLinkIcon="force" to={link.value}>
                  {htmlParser(link.label)}
                </StyledLink>
              )
            })}
          </SecondLine>
        )}
      </MainNav>
    </Wrapper>
  )
}

export default Footer
