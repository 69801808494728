import React, { Component } from 'react'
import ContainerMain from '../ContainerMain'
import GridLayout from './components/GridLayout'
import isServerSide from '../../../../modules/is-server-side'

class ContainerMainDev extends Component {
  state = {
    showGrid: (!isServerSide() && JSON.parse(window.localStorage.getItem('showLayoutGrid'))) || false,
  }

  handleKeyDown = (event) => {
    if (event.keyCode === 186 && event.ctrlKey && event.shiftKey) {
      const newState = !this.state.showGrid
      if (!isServerSide()) {
        localStorage.setItem('showLayoutGrid', newState + '')
      }
      this.setState({ showGrid: newState })
    }
  }

  componentWillMount = () => {
    if (!isServerSide()) document.addEventListener('keydown', this.handleKeyDown)
  }

  componentWillUnmount = () => {
    if (!isServerSide()) document.removeEventListener('keydown', this.handleKeyDown)
  }

  render = () => {
    return (
      <ContainerMain {...this.props}>
        {this.props.children}
        {this.state.showGrid && <GridLayout />}
      </ContainerMain>
    )
  }
}

ContainerMainDev.propTypes = ContainerMain.propTypes

export default ContainerMainDev
