import styled from 'styled-components'
import Link from '../../../../../../../../components/Link'

const LinkNavTool = styled(Link)`
  color: ${(props) => props.theme.color.textSecondary};
  font-size: ${(props) => props.theme.fontSize.small};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.small};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.small};
  }
  display: flex;
`

export default LinkNavTool
