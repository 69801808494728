import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import checkForValueInUserAgent from '../../../../modules/check-for-value-in-useragent'
import Sticker from 'react-stickyfill'
import AdUnit from '../../../../components/AdUnit'
import { useMobileKeyboard } from '../../../../modules/media-query'

const Wrapper = styled.div`
  width: 300px;
  z-index: 1;
  transform: translate3d(0, 0, 0);
  display: ${(props) => (props.mobileKeyboard ? 'none' : 'block')};
  @media (max-width: 1380px) {
    width: 100%;
    position: fixed;
    left: 0;
    bottom: ${(props) => (props.isIphoneX ? '20px' : '5px')};
    top: auto;
    text-align: center;
  }
`
const SkyAdUnit = styled(AdUnit)`
  position: sticky;
  top: 0;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000px;
  -moz-perspective: 1000px;
  -ms-perspective: 1000px;
  perspective: 1000px;
`

const AdSky = ({ active, adId, ...props }) => (
  <Wrapper mobileKeyboard={useMobileKeyboard()} isIphoneX={checkForValueInUserAgent('iphonex')} {...props}>
    {active && (
      <Sticker>
        <SkyAdUnit adId={adId} />
      </Sticker>
    )}
  </Wrapper>
)

AdSky.propTypes = {
  active: PropTypes.bool,
  adId: PropTypes.string,
}

AdSky.defaultProps = {
  active: true,
  adId: 'S2',
}

export default AdSky
