import React, { Component, useContext } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import ItemSpecialNav, { StyledIcon0ItemSpecialNav } from './components/ItemSpecialNav'
import NavLink from '../../../../../../components/NavLink'
import Collapsible from './components/Collapsible'
import ContextMenu from '../../../../../../components/ContextMenu'
import { useUserContext } from '../../../../../../components/ContextUser'

const MainNav = styled.div`
  width: ${(props) => props.theme.sizes.col9};
  margin-right: ${(props) => props.theme.sizes.gapCol};
  column-width: ${(props) => props.theme.sizes.col3};
  -moz-column-count: 4;
  -webkit-column-count: 4;
  column-count: 4;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    width: ${(props) => props.theme.tablet.sizes.col9};
    margin-right: ${(props) => props.theme.tablet.sizes.gapCol};
    column-width: ${(props) => props.theme.tablet.sizes.col3};
  }
  @media (max-width: 615px) {
    width: ${(props) => props.theme.mobile.sizes.col9};
    margin-right: ${(props) => props.theme.mobile.sizes.gapCol};
    column-width: ${(props) => props.theme.mobile.sizes.col3};
  }
`

const SpecialNav = styled.div`
  width: calc(
    ${(props) => props.theme.sizes.col2.replace('calc', '')} + ${(props) => props.theme.sizes.gapEdge} + 10px
  );
  margin-right: -${(props) => props.theme.sizes.gapEdge};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    width: calc(
      ${(props) => props.theme.tablet.sizes.col2.replace('calc', '')} + ${(props) => props.theme.tablet.sizes.gapEdge} +
        10px
    );
    margin-right: -${(props) => props.theme.tablet.sizes.gapEdge};
  }
  @media (max-width: 615px) {
    width: calc(
      ${(props) => props.theme.mobile.sizes.col2.replace('calc', '')} + ${(props) => props.theme.mobile.sizes.gapEdge} +
        10px
    );
    margin-right: -${(props) => props.theme.mobile.sizes.gapEdge};
  }
`

const StyledItemSpecialNav = styled(ItemSpecialNav)`
  border-color: ${(props) => props.theme.color.textTertiary};
  border-style: solid;
  border-width: 0;
  border-bottom-width: 1px;

  &:first-child {
    border-top-width: 1px;
  }
  @media (max-width: 700px) {
    order: ${(props) => props.order && props.order};
  }
`
const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  background-color: ${(props) => props.theme.color.background};
  padding: ${(props) => props.theme.sizes.gapVertical} ${(props) => props.theme.sizes.gapEdge};
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    background-color: ${(props) => props.theme.tablet.color.background};
    padding: ${(props) => props.theme.tablet.sizes.gapVertical} ${(props) => props.theme.tablet.sizes.gapEdge};
  }
  @media (max-width: 615px) {
    background-color: ${(props) => props.theme.mobile.color.background};
    padding: ${(props) => props.theme.mobile.sizes.gapVertical} ${(props) => props.theme.mobile.sizes.gapEdge};
  }
  ${(props) =>
    !props.open &&
    css`
      display: none;
    `};
  @media (max-width: 1000px) {
    ${MainNav} {
      width: ${(props) => props.theme.tablet.sizes.col10};
    }
  }
  @media (max-width: 615px) {
    ${MainNav} {
      width: ${(props) => props.theme.mobile.sizes.col10};
    }
  }
  @media (max-width: 900px) {
    ${MainNav} {
      width: 75%;
      column-count: 3;
    }
    ${SpecialNav} {
      width: 25%;
    }
  }
  @media (max-width: 770px) {
    ${MainNav} {
      width: 65%;
      column-count: 2;
    }
    ${SpecialNav} {
      width: 35%;
    }
  }
  @media (max-width: 700px) {
    flex-direction: column-reverse;
    ${MainNav} {
      width: 100%;
      column-count: 1;
    }
    ${SpecialNav} {
      margin-right: 0;
      width: 100%;
      margin-bottom: 1em;
      display: flex;
      flex-direction: column;
    }
    ${StyledItemSpecialNav} {
      background-color: rgba(0, 0, 0, 0.06);
      margin-bottom: 1.125em;
      border-bottom: 0;
      border-top: 0;
      padding: 0.75em;
      padding-left: 5px;
      display: flex;
      font-weight: 400;
      &:last-child {
        background-color: rgba(0, 0, 0, 0.2);
        justify-content: center;
        text-align: center;
        color: white;
        color: #fff;
        padding-left: 0;
        font-weight: 500;
        ${StyledIcon0ItemSpecialNav} {
          color: white;
          color: #fff;
          margin: 0;
        }
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.15) !important;
      }
    }
  }
`
const NavigationBlock = styled.div`
  break-inside: avoid-column;
  display: flex;
  flex-direction: column;
  padding-bottom: 1em;
  @media (max-width: 700px) {
    margin: 0;
    padding: 0;
    border-color: ${(props) => props.theme.color.textTertiary};
    border-style: solid;
    border-width: 0;
    border-bottom-width: 1px;
    &:first-child {
      border-top-width: 1px;
    }
  }
`
const NavigationLink = styled(({ type, ...props }) => <NavLink {...props} />)`
  margin: 0.2em 0;
  padding: 0;
  white-space: normal;
  @media (max-width: 700px) {
    display: none;
  }
  ${(props) =>
    props.type === 'big' &&
    css`
      color: ${(props) => props.theme.color.stol};
      font-weight: ${(props) => props.theme.fontWeight.bold};
      font-size: ${(props) => props.theme.fontSize.text};
      @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
        font-size: ${(props) => props.theme.tablet.fontSize.text};
      }
      @media (max-width: 615px) {
        font-size: ${(props) => props.theme.mobile.fontSize.text};
      }

      &:hover {
        color: ${(props) => props.theme.color.stol};
      }
    `}
  &:before {
    left: -1em;
  }
`

const MoreNav = ({ open, closeDropdownCallback, meta, ...props }) => {
  const { responseData, mainNavigation, specialNavigation } = useContext(ContextMenu)
  const { profile } = useUserContext()
  return (
    <Wrapper open={open} onMouseLeave={closeDropdownCallback} {...props}>
      <MainNav>
        {mainNavigation.map((array, i1) => (
          <NavigationBlock key={'mainNavigationL1' + i1}>
            {array.map(({ name, url, type }, i2) => (
              <NavigationLink key={'mainNavigationL2' + name + i1 + i2} type={type} to={url}>
                {name}
              </NavigationLink>
            ))}
            <Collapsible
              key={'mainNavigationMobileL2' + i1 + i1}
              currentColumn={array}
              closeDropdownCallback={closeDropdownCallback}
            />
          </NavigationBlock>
        ))}
      </MainNav>
      <SpecialNav>
        {specialNavigation.map(({ name, url, icon }, i) => {
          if (name.localeCompare('Login') === 0) {
            name = !!profile ? 'Profil' : name
            icon = !!profile ? 'loggedIn' : icon
            url = !!profile ? '/profil' : url
          }

          return (
            <StyledItemSpecialNav key={'specialNavigation' + name + i} url={url} icon={icon}>
              {name}
            </StyledItemSpecialNav>
          )
        })}
      </SpecialNav>
    </Wrapper>
  )
}

MoreNav.propTypes = {
  mainNavigation: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        url: PropTypes.string,
        type: PropTypes.oneOf(['big', 'default']).isRequired,
      }),
    ),
  ),
  specialNavigation: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: ItemSpecialNav.propTypes.url,
      icon: ItemSpecialNav.propTypes.icon,
    }),
  ),
  open: PropTypes.bool,
  onMouseLeaveCallBack: PropTypes.func,
}

MoreNav.defaultProps = {
  open: false,
}

export default MoreNav
