import React from 'react'
import styled from 'styled-components'
import Upscroll from 'react-scroll-up'
import finger from '../../images/redarrow4.png'

const Wrapper = styled.div`
  z-index: 1300;
  
   @media (max-width: 600px) {
    display:none
   }
`

const ScrollStyle = {
  position: 'fixed',

  bottom: 40,
  right: 30,
  width: 50,
  height: 50,
  cursor: 'pointer',
  transitionDuration: '0.2s',
  transitionTimingFunction: 'linear',
  transitionDelay: '0s',
  'z-Index': 1300,
}
const ScrollStyleMobile = {
  position: 'absolut',
  bottom: 150,
  right: 30,
  width: 50,
  height: 50,
  cursor: 'pointer',
  transitionDuration: '0.2s',
  transitionTimingFunction: 'linear',
  transitionDelay: '0s',
  'z-Index': 1300,
}

const StyledFinger = styled.div`
  background-color: white;
  margin-top: 10px;
  width: 50px;
  height: 50px;
  border-radius: 40px;
  z-index: 1299;
  -webkit-box-shadow: 2px 2px 5px 0px rgba(99, 99, 99, 0.37);
  -moz-box-shadow: 2px 2px 5px 0px rgba(99, 99, 99, 0.37);
  box-shadow: 2px 2px 5px 0px rgba(99, 99, 99, 0.37);
  &:hover {
    background-color: rgb(244, 244, 244);
    -webkit-box-shadow: inset 2px 2px 5px 0px rgba(99, 99, 99, 0.37);
    -moz-box-shadow: inset 2px 2px 5px 0px rgba(99, 99, 99, 0.37);
    box-shadow: inset 2px 2px 5px 0px rgba(99, 99, 99, 0.37);
  }
`
const StyledFingerImage = styled.img`
  width: 50px;
  height: 50px;
  margin-right: auto;
  margin-left: auto;
  z-index: 1300;
`

const UpScroll = ({ ...props }) => (
  <Wrapper {...props}>
    {
      <Upscroll showUnder={300} style={ScrollStyle}>
        <StyledFinger>
          <StyledFingerImage src={finger} />
        </StyledFinger>
      </Upscroll>
    }
  </Wrapper>
)

export default UpScroll
