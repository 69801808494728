import React, { memo, useEffect, useState } from 'react'
import styled from 'styled-components'
import SafariSvg from './images/safari.js'
import Text from '../Text'
import translate from '../../modules/translate'
import { detect } from 'detect-browser'

const Wrapper = styled.div`
  width: ${(props) => props.theme.default.breakPoint + 'px'};
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  align-self: center;
  background-color: white;
  padding-bottom: ${(props) => props.theme.default.sizes.gapVerticalHalf};
  padding-top: ${(props) => props.theme.default.sizes.gapVerticalHalf};
  margin-bottom: ${(props) => props.theme.tablet.sizes.gapVerticalHalf};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 600 + 'px'}) {
    align-self: baseline;
    margin-left: calc((100% - 1080px - 160px - 300px) / 2 + 160px);
  }
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 460 + 'px'}) {
    align-self: baseline;
    margin-left: calc((100% - 1080px - 300px) / 2);
  }
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 300 + 'px'}) {
    align-self: center;
    margin-left: 0;
  }
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    width: 100%;
    padding-bottom: ${(props) => props.theme.tablet.sizes.gapVerticalHalf};
    padding-top: ${(props) => props.theme.tablet.sizes.gapVerticalHalf};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    padding-bottom: ${(props) => props.theme.mobile.sizes.gapVerticalHalf};
    padding-top: ${(props) => props.theme.mobile.sizes.gapVerticalHalf};
    margin-bottom: ${(props) => props.theme.mobile.sizes.gapVerticalHalf};
  }
  -webkit-box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.35);
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.35);
`

const StolLogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  padding-right: ${(props) => props.theme.sizes.gapEdge};
  padding-left: ${(props) => props.theme.sizes.gapEdge};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    padding-right: ${(props) => props.theme.tablet.sizes.gapEdge};
    padding-left: ${(props) => props.theme.tablet.sizes.gapEdge};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    padding-right: ${(props) => props.theme.mobile.sizes.gapEdge};
    padding-left: ${(props) => props.theme.mobile.sizes.gapEdge};
  }
`

const StyledLogo = styled(SafariSvg)`
  width: 50px;
  height: 50px;
`

const StyledFirstMessageText = styled(Text)`
  padding-left: 15px;
  padding-right: 10px;
  padding-top: 2px;
  font-size: ${(props) => props.theme.default.fontSize.title2};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.title2};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.title2};
  }
`

const StyledSecondMessageWrapper = styled.div`
  width: 1000px;
  margin-top: 10px;
  padding-right: ${(props) => props.theme.sizes.gapEdge};
  padding-left: ${(props) => props.theme.sizes.gapEdge};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    padding-right: ${(props) => props.theme.tablet.sizes.gapEdge};
    padding-left: ${(props) => props.theme.tablet.sizes.gapEdge};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    width: 95%;
    -webkit-text-size-adjust: 100%;
    padding-right: ${(props) => props.theme.mobile.sizes.gapEdge};
    padding-left: ${(props) => props.theme.mobile.sizes.gapEdge};
  }
`
const StyledSecondMessageText = styled(Text)`
  font-size: ${(props) => props.theme.default.fontSize.text};
  a {
    font-size: ${(props) => props.theme.default.fontSize.text};
  }
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.text};
    a {
      font-size: ${(props) => props.theme.tablet.fontSize.text};
    }
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.text};
    a {
      font-size: ${(props) => props.theme.mobile.fontSize.text};
    }
  }
`

const SafariMessage = () => {
  const [isSafari, setSafari] = useState(false)

  useEffect(() => {
    const browser = detect()
    console.log(browser)
    setSafari(
      browser && (browser.name === 'safari' || browser.name === 'ios') && parseInt(browser.version.split('.')[0]) <= 12,
    )
  }, [])

  if (!isSafari) {
    return false
  }

  return (
    <Wrapper>
      <StolLogoWrapper>
        <StyledLogo />
        <StyledFirstMessageText>{translate('safari_message_1')}</StyledFirstMessageText>
      </StolLogoWrapper>
      <StyledSecondMessageWrapper>
        <StyledSecondMessageText>{translate('safari_message_2')}</StyledSecondMessageText>
        <StyledSecondMessageText>
          {translate('safari_message_3', { link: 'https://browser-update.org/de/update-browser.html' })}
        </StyledSecondMessageText>
      </StyledSecondMessageWrapper>
    </Wrapper>
  )
}

export default memo(SafariMessage)
