import { css } from 'styled-components'

const appBottomPadding = css`
  padding-bottom: 60px;
  flex: 1;
`

const iPhoneXPadding = css`
  padding-bottom: 80px;
`

export default appBottomPadding
export { iPhoneXPadding }
