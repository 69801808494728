import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.em`
  font-style: normal;
  cursor: default;
  color: ${(props) => props.theme.color.textSecondary};
  font-size: ${(props) => props.theme.fontSize.small};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.small};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.small};
  }
  margin: 0 0.5em;
`

const Delimiter = ({ ...props }) => <Wrapper {...props}>|</Wrapper>

export default Delimiter
