import React, { useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import translate from '../../modules/translate'

const EmailVerificationResult = ({ success }) => {
  const [open, setOpen] = useState(true)

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="email-verification-result-dialog-title"
      aria-describedby="email-verification-result-dialog-description"
    >
      <DialogTitle id="email-verification-result-dialog-title">
        {success ? translate('email_verification_result_success') : translate('email_verification_result_failure')}
      </DialogTitle>
      {!success && (
        <DialogContent>
          <DialogContentText id="email-verification-result-dialog-description">
            {translate('email_verification_result_failure_description')}
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={() => setOpen(false)} color="primary">
          {translate('close')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EmailVerificationResult
