import React from 'react'
import styled from 'styled-components'
import Link from '../../../../../../components/Link'
import Logo from '../../../../../../images/StolLogo'
import isServerSide from '../../../../../../modules/is-server-side'
import routes from '../../../../../../topics/routes'

const Wrapper = styled(Link)`
  display: block;
  overflow: hidden;
`

const StyledLogo = styled(Logo)``

const StyledCustomLogo = styled.img``

const scrollToTop = () => {
  if (!isServerSide()) {
    window.scrollTo({
      top: 0,
      behavior: window.location.pathname === '/' ? 'smooth' : 'instant',
    })
  }
}

const StolLogoHeader = ({ img, ...props }) => (
  <Wrapper id="stolLogoWrapper" to={routes.generatePathByRouteName('home')} onClick={scrollToTop} {...props}>
    {img && <StyledCustomLogo id="stolLogo" src={img} />}
    {!img && <StyledLogo id="stolLogo" />}
  </Wrapper>
)

export default StolLogoHeader
