import styled from 'styled-components'
import PropTypes from 'prop-types'

const ContainerMain = styled.main`
  width: 1078px;
  max-width: 100%;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  position: relative;
`

export default ContainerMain

ContainerMain.propTypes = {
  children: PropTypes.node,
}
